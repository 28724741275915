import React, { FC, SVGAttributes } from "react"
import SVG from "./svg"

const Pokedex: FC<SVGAttributes<SVGElement>> = (props) => {
  return (
    <SVG
      viewBox="0 0 28 32"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M2 0a2 2 0 00-2 2v10h9l2.7-3.6.3-.4h16V2a2 2 0 00-2-2H2zm26 10H13l-2.7 3.6-.3.4H0v16a2 2 0 002 2h24a2 2 0 002-2V10zM8 6a2 2 0 11-4 0 2 2 0 014 0zm16 10l-4 4 4 4v-8z"
        clipRule="evenodd"
      ></path>
    </SVG>
  )
}

export default Pokedex
