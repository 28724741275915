import React, { FC } from "react"
import { ThemeType } from "../theme"

import Sun from '../images/icons/sun'
import Moon from '../images/icons/moon'
import styled from "styled-components"

interface Props {
    themeMode: ThemeType;
    toggleTheme: () => void;
}

const ToggleButton = styled.button`
    background: transparent;
    border: none;
    width: 40px;
    margin: 0;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    @supports(padding: max(0px)) {
        margin-right: max(0px, env(safe-area-inset-right));
    }
`

const ToggleTheme: FC<Props> = ({ themeMode, toggleTheme }) => {
    return (
        <ToggleButton onClick={toggleTheme} aria-label={"Toggle " + themeMode + " mode"}>
            {themeMode === ThemeType.Light ? <Sun width="100%" fill="white" /> : <Moon width="100%" fill="black" />}
        </ToggleButton>
    )
}

export default ToggleTheme
