module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/web/src/containers/layout.tsx"},
    },{
      plugin: require('../plugins/source-pokemon/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pokédex Go","short_name":"PokédexGo","start_url":"/","background_color":"#F5F5F5","theme_color":"#DC1826","display":"standalone","icon":"favicons/pokedex-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2b276d16fa24dd0336bc40b771a5a05a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
