import React, { FC } from 'react';
import styled from 'styled-components'
import Link from '../link';

const FooterWrapper = styled.footer`
  width: 100%;
  position: relative;
  user-select: none;
  z-index: 200;
  min-height: 0px;
  min-width: 0px;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
`

const FooterPlaceholder = styled.div`
  position: relative;
  height: calc(51px + max(0px, env(safe-area-inset-bottom)));
  min-height: 0px;
  min-width: 0px;
  z-index: 0;
`

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[2]};
  background: ${({ theme }) => theme.colors.white};
  padding-bottom: env(safe-area-inset-bottom);
`

const FooterNav = styled.nav`
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`

export const FooterLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.colors.white};
  padding: 8px 2px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .005em;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  flex: 1;
  z-index: 100;
  position: relative;
  overflow: hidden;

  &.active {
    p {
      color: ${({ theme }) => theme.colors.red}!important;
    }
    svg {
      fill: ${({ theme }) => theme.colors.red}!important;
    }
  }

  &::before {
    content: '';
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 50%;
    transform-origin: center center;
    transform: scale(1.25);
    opacity: .00525;
    background-color: ${({theme}) => theme.colors.grey[2]};
    position: absolute;
    top: -25%;
    /* transition: opacity .5s ease-out; */
    z-index: 0;
  }

  &:active::before {
    transform: scale(.01);
    opacity: 1;
  }
  &:not(:active)::before {
    transition: transform .25s ease-out, opacity .275s ease-out;
  }

  & > * {
    position: relative;
    z-index: 1;
  }
`

interface Props {
  children: React.ReactNode;
}

const Footer: FC<Props> = ({ children }) => (
  <FooterWrapper>
    <FooterPlaceholder />
    <FooterContainer>
      <FooterNav>
        {children}
      </FooterNav>
    </FooterContainer>
  </FooterWrapper>
)

export default React.memo(Footer)

