import React, { FC } from 'react';
import styled from 'styled-components'
import { ThemeType } from '../../theme';
import Box from '../box';
import Link from '../link';
import ToggleTheme from '../toggle-theme';
import { Highlight, Orb } from './orb';

const HEADER_HEIGHT = 45
const HEADER_TRIANGLE_HEIGHT = 16

const HeaderWrapper = styled.header`
  width: 100%;
  position: relative;
  user-select: none;
  z-index: 200;
  min-height: 0px;
  min-width: 0px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
`

const HeaderPlaceholder = styled.div`
  position: relative;
  height: calc(env(safe-area-inset-top) + ${HEADER_HEIGHT + HEADER_TRIANGLE_HEIGHT}px);
  min-height: 0px;
  min-width: 0px;
  z-index: 0;
`

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  /* box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 2px 9px 1px rgba(0, 0, 0, 0.12), 0 4px 2px -2px rgba(0, 0, 0, 0.2); */
`

const Content = styled.div`
  width: 100%;
  padding-top: env(safe-area-inset-top);
  padding-left: 27%;
  min-height: ${HEADER_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.red};
  backdrop-filter: blur(10px);

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;

  @media (min-width: 600px) {
    /* padding-left: 180px; */
  }

  /* Bottom triangle */
  &::before
  {
    content: '';
    position: absolute;
    height: 0px;
    width: 0px;
    border-top: 6px solid ${({ theme }) => theme.colors.red};
    border-left: 20px solid ${({ theme }) => theme.colors.red};
    border-right: 20px solid transparent;
    border-bottom: 6px solid transparent;
    bottom: -12px;
    left: 65%;
    z-index: 2;
   
    @media (min-width: 600px) {
      /* left: 180px; */
    }
  }

  /* Bottom segment */
  &::after
  {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.red};
    height: 12px;
    width: 65%;
    bottom: -12px;
    left: 0;
    display: block;
    z-index: 1;
    box-shadow: 18px -16px 0 -9px ${({ theme }) => theme.colors.red};
    /* border-bottom-right-radius: 8px; */

    @media (min-width: 600px) {
      /* width: 180px; */
    }
  }
`;

const HeaderTitle = styled(Link)`
  color: #fff;
  font-size: ${props => props.theme.fontSizes[5]}px;
  letter-spacing: -.0075em;
  line-height: 1;
  font-weight: bold;
  margin-top: 6px;
`

const HeaderNav = styled.nav`
  width: 100%;
  height: 16px;
  background: ${({ theme }) => theme.colors.darkRed};
  display: flex;
  padding-left: calc(30% + 10px);
  align-items: flex-end;
  position: relative;

  @media (min-width: 600px) {
    padding-left: calc(180px + 10px);
  }
`

export const HeaderLink = styled(Link)`
  color: #fff;
  padding: 6px 10px;
  font-size: .65rem;
  letter-spacing: .005em;
  transition: all .25s ease-out;
  display: block;
  z-index: 3;
`

interface Props {
  children: React.ReactNode;
  siteTitle: string;
  themeMode: ThemeType;
  toggleTheme: () => void;
}

const Header: FC<Props> = ({ children, siteTitle = 'Pok&eacute;mon Go', themeMode, toggleTheme }) => (
  <HeaderWrapper>
    <HeaderPlaceholder />
    <HeaderContainer>
      <Content>
        <Orb />
        <HeaderTitle to="/">
          {siteTitle}
        </HeaderTitle>
        <Box display="flex" alignItems="center">
          <HeaderLink to="/tools">Tools</HeaderLink>
          <ToggleTheme themeMode={themeMode} toggleTheme={toggleTheme} />
        </Box>
      </Content>
      <HeaderNav>
        <Highlight />
        {children}
      </HeaderNav>
    </HeaderContainer>
  </HeaderWrapper>
)

export default Header

