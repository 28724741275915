// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-buddy-excitement-tsx": () => import("./../../../src/pages/buddy-excitement.tsx" /* webpackChunkName: "component---src-pages-buddy-excitement-tsx" */),
  "component---src-pages-dex-tsx": () => import("./../../../src/pages/dex.tsx" /* webpackChunkName: "component---src-pages-dex-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-raids-tsx": () => import("./../../../src/pages/raids.tsx" /* webpackChunkName: "component---src-pages-raids-tsx" */),
  "component---src-pages-research-tasks-tsx": () => import("./../../../src/pages/research-tasks.tsx" /* webpackChunkName: "component---src-pages-research-tasks-tsx" */),
  "component---src-pages-team-rocket-tsx": () => import("./../../../src/pages/team-rocket.tsx" /* webpackChunkName: "component---src-pages-team-rocket-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-pages-trainer-levels-tsx": () => import("./../../../src/pages/trainer-levels.tsx" /* webpackChunkName: "component---src-pages-trainer-levels-tsx" */),
  "component---src-pages-type-effectiveness-tsx": () => import("./../../../src/pages/type-effectiveness.tsx" /* webpackChunkName: "component---src-pages-type-effectiveness-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-raid-tsx": () => import("./../../../src/templates/raid.tsx" /* webpackChunkName: "component---src-templates-raid-tsx" */)
}

