import React, { FC, SVGAttributes } from "react"
import SVG from "./svg"

const Research: FC<SVGAttributes<SVGElement>> = (props) => {
  return (
    <SVG
      viewBox="0 0 534 506"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M0 0v506h158V363.5h135L364 506h170l-89.5-168.5C602 241 524.5 0 396 0H0zm160 142v79.5h165c40.5 0 41.5-79.5 0-79.5H160z"
        clipRule="evenodd"
      ></path>
    </SVG>
  )
}

export default Research
