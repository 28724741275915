import styled from 'styled-components'

const Orb = styled.div`
  width: 60px;
  height: 60px;
  position: absolute;
  top: calc(7px + env(safe-area-inset-top));
  left: 15%;
  transform: scale(.75) translateX(-50%);
  transform-origin: top left;
  border-radius: 30px;
  background: #3498DB;
  background-image: radial-gradient(circle at 15px 15px, #64ABDB 33%, #337AA9 100%);
  /* border: 2px solid #4A4A4A; */
  border: 2px solid #6f1515;
  box-shadow: 0px 2px 3px 1px rgba(0,0,0,0.05), 1px 2px 3px 0px rgba(0,0,0,0.20), inset 0px 0px 3px 1px rgba(255,255,255,0.25);
  z-index: 201;
  overflow: hidden;
  animation: glow .3s ease-in-out 3;

  @media (min-width: 600px) {
    left: 90px;
  }

  @supports(top: max(0px)) {
    top: max(7px, env(safe-area-inset-top));
  }

  &::before, &::after
  {
    content: '';
    position: absolute;
    display: block;
  }
  &::before
  {
    width: 35%;
    height: 35%;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, .15);
    left: 28%;
    top: 10%;

    box-shadow: -13px 15px 0 -5px rgba(255, 255, 255, .1);
  }

  &::after
  {
    width: 65%;
    height: 50%;
    background-color: rgba(0, 0, 0, 0.04);
    bottom: -5%;
    right: -10%;
    border-radius: 50%;
    transform: rotate(-20deg);
  }

  @keyframes glow {
    100% {
      background-color: rgb(59,159,226);
      /*background-image: radial-gradient(circle at 15px 15px, #4DAFEF 32%, #2989C8 100%);*/
      /* background-image: radial-gradient(circle at 15px 15px, #68BCF2 32%, #3A95D0 100%); */
      /*box-shadow: 0px 0px 4px 4px rgba(59,160,227, .45), 0px 2px 3px 1px rgba(0,0,0,0.05), 1px 2px 3px 0px rgba(0,0,0,0.20), inset 0px 0px 3px 1px rgba(255,255,255,0.25);*/
      box-shadow: 0px 0px 9px 10px rgba(144,208,249,0.5), 0px 2px 3px 1px rgba(0,0,0,0.05), 1px 2px 3px 0px rgba(0,0,0,0.20), inset 0px 0px 3px 1px rgba(255,255,255,0.25);
    }

  }
`

const Highlight = styled.span`
  width: 70px;
  background-color: #fff;
  height: 1px;
  opacity: 0;
  position: absolute;
  bottom: 6px;
  left: 0;
  will-change: transform, width;
  transition: transform .15s ease-out, width .15s .05s ease-out;
`

export { Orb, Highlight }

