import React, { FC, SVGAttributes } from "react"
import SVG from "./svg"

const Pokeball: FC<SVGAttributes<SVGElement>> = (props) => {
  return (
    <SVG
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M31.97 17h-8.032a8.001 8.001 0 01-15.876 0H.03C.547 25.37 7.499 32 16 32c8.5 0 15.453-6.63 15.97-15zm0-2h-8.032a8.001 8.001 0 00-15.876 0H.03C.547 6.63 7.499 0 16 0c8.5 0 15.453 6.63 15.97 15zM16 22a6 6 0 100-12 6 6 0 000 12z"
        clipRule="evenodd"
      ></path>
    </SVG>
  )
}

export default Pokeball
