import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

const Container = styled.main<SpaceProps>`
    width: 100%;
    max-width: 100%;
    ${space}

    @media (min-width: 1024px) {
        max-width: 1024px;
        margin: 0 auto;
    }
`

export default Container;