/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from 'styled-components'

import Header, { HeaderLink } from "../components/header/header"
import { darkTheme, lightTheme, ThemeType } from '../theme'
import { useDarkMode } from "../hooks/useDarkMode"

import GlobalStyles from "./global"
import "./layout.css"
import Container from "./container"
import Footer, { FooterLink } from "../components/footer/footer"
import Text from "../components/text"

import RaidIcon from '../images/icons/raid-simple'
import PokedexIcon from '../images/icons/pokedex'
import PokeballIcon from '../images/icons/pokeball'
import TeamRocketIcon from '../images/icons/team-rocket'
import ResearchIcon from "../images/icons/research"

interface Props {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  const [theme, toggleTheme, componentMounted] = useDarkMode()
  const themeMode = theme === ThemeType.Light ? lightTheme : darkTheme

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      sanitySiteSettings {
        title
        description
        author {
          name
        }
      }
    }
  `)

  if (!componentMounted) {
    return <div />
  }

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyles />
      <Header themeMode={theme} toggleTheme={toggleTheme} siteTitle={data.sanitySiteSettings.title || `Pokedex Go`}>
        {/* <HeaderLink to="/tools">
          More
        </HeaderLink> */}
      </Header>
      <Container py={6}>
        {children}
      </Container>
      <Footer>
        <FooterLink to="/dex" activeClassName="active">
          <PokedexIcon height="18px" fill="black" />
          <Text color="black" fontSize={0} mt={2} mb={0}>Dex</Text>
        </FooterLink>
        <FooterLink to="/raids" activeClassName="active">
          <RaidIcon height="19px" fill="black" />
          <Text color="black" fontSize={0} mt={2} mb={0}>Raids</Text>
        </FooterLink>
        <FooterLink to="/" activeClassName="active" aria-label="Home">
          <PokeballIcon height="22px" fill="black" />
        </FooterLink>
        <FooterLink to="/team-rocket" activeClassName="active">
          <TeamRocketIcon height="16px" fill="black" />
          <Text color="black" fontSize={0} mt={2} mb={0}>Team Rocket</Text>
        </FooterLink>
        <FooterLink to="/research-tasks" activeClassName="active">
          <ResearchIcon height="17px" fill="black" />
          <Text color="black" fontSize={0} mt={2} mb={0}>Research</Text>
        </FooterLink>
      </Footer>
    </ThemeProvider>
  )
}

export default Layout
