import styled from 'styled-components'
import { space, layout, color, SpaceProps, LayoutProps, flexbox, FlexboxProps, display, DisplayProps, fontSize, FontSizeProps, textAlign, TextAlignProps, lineHeight, LineHeightProps, fontWeight, FontWeightProps, backgroundColor, BackgroundColorProps } from 'styled-system'

interface Props extends SpaceProps, LayoutProps, FlexboxProps, DisplayProps, FontSizeProps, FontWeightProps, TextAlignProps, LineHeightProps, BackgroundColorProps {}

const Text = styled.p<Props>`
  color: inherit;
  transition: color .15s ease-out;
  line-height: 1.45;
  margin-bottom: ${({theme}) => theme.space[5]}px;
  
  ${space}
  ${layout}
  ${color}
  ${backgroundColor}
  ${flexbox}
  ${display}
  ${fontSize}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
`

export default Text;