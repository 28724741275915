import { createGlobalStyle } from 'styled-components'
import { ThemeProps } from '../theme'

const GlobalStyles = createGlobalStyle<{ theme: ThemeProps }>`
  *, *::before, *::after
  {
    box-sizing: border-box;
  }
  html, body {
    background: ${props => (props.theme.colors.red)};
    color: ${({ theme }) => theme.colors.text};
    height: 100%;
    display: block;
    padding: 0;
    margin: 0;
  }
  html {
    width: 100%;
    position: relative;
    overflow-y: scroll;
    overscroll-behavior-y: none;

    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 112.5%/1.45em -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;

    color: hsla(0, 0%, 0%, 0.8);
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
  }
  #___gatsby {
    min-height: 100%;
    min-height: 100vh;
    display: flex;
    background: ${props => (props.theme.colors.body)};
  }
  #gatsby-focus-wrapper {
    flex: 1;
    pointer-events: none!important;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-basis: auto;
    flex-shrink: 0;
    min-height: 0px;
    min-width: 0px;
    width: 100%;
    & > * {
      pointer-events: auto;
    }
  }
`

export default GlobalStyles;