import React, { FC, SVGAttributes } from "react"
import SVG from "./svg"

const Research: FC<SVGAttributes<SVGElement>> = (props) => {
  return (
    <SVG
      viewBox="0 0 52 41"
      {...props}
    >      
        <path
          d="M8.136 25.245a5.5 5.5 0 109.576 5.413l.871.492a6.5 6.5 0 01-11.317-6.397l.87.492zM41.16 32.819a5.5 5.5 0 01-7.532-1.945l-.861.508a6.5 6.5 0 1011.198-6.602l-.861.508a5.5 5.5 0 01-1.945 7.53z"
        ></path>
        <path
          fillRule="evenodd"
          d="M39.998 6.14L40 6a6 6 0 10-11.753 1.708c-1.354 1.527-2.49 3.455-2.49 3.792 0 .155-.192-.198-.516-.796v-.002l-.003-.003c-.38-.7-.94-1.734-1.587-2.676a6 6 0 10-11.648-2.188 3.195 3.195 0 00-.246.165c-2 1.5-10.5 14.5-11.5 18.5s.5 16.5 12 16.5 13.5-12 13.5-12 2 12 14 12 12.5-13 11.5-16.5c-.974-3.41-8.117-16.31-11.259-18.36zM24.758 16.5v6h2v-6h-2zm-4 12a8 8 0 11-16 0 8 8 0 0116 0zm24.927 4a8 8 0 10-13.856-8 8 8 0 0013.856 8z"
          clipRule="evenodd"
        ></path>
    </SVG>
  )
}

export default Research
