import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby"
import styled from "styled-components";
import { fontSize, layout, space, variant, SpaceProps, LayoutProps, FontSizeProps, lineHeight, LineHeightProps, textAlign, TextAlignProps, } from "styled-system";

interface Props extends GatsbyLinkProps<any>, SpaceProps, LayoutProps, FontSizeProps, LineHeightProps, TextAlignProps {}

const Link = styled(GatsbyLink)<Props>`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
    line-height: 1;
    ${textAlign}
    ${fontSize}
    ${space}
    ${layout}
    ${lineHeight}
    ${variant({
        variants: {
            block: {
                display: "block"
            }
        }
    })}
`;

export default Link;